import { render, staticRenderFns } from "./Programas.vue?vue&type=template&id=37213b38&scoped=true&"
import script from "./Programas.vue?vue&type=script&lang=js&"
export * from "./Programas.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37213b38",
  null
  
)

export default component.exports